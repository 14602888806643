import { action, makeObservable } from 'mobx';
import cn from 'classnames';
import axios, { type AxiosError, type AxiosPromise } from 'axios';

import { type FeatureTogglesModel } from '~/util/feature-toggles/Models/FeatureToggles.model';
import { type GlobalDynamicStore } from '~/global/global-dynamic/Stores/GlobalDynamic.store';
import { type MagicModalStore } from '~/components/magic-modal/Stores/MagicModal.store';
import { type MagicOverlayStore } from '~/components/magic-overlay/Stores/MagicOverlay.store';
import { type GiftCardScannerStore } from '~/engage/gift-card-scanner/Stores/GiftCardScanner.store';
import { type ViewStateView } from '~/util/viewState/ViewState.interface';
import { PromiseError } from '~/util/messaging/promise-error/PromiseError';
import { SellGiftCardModel } from '~/engage/sell-gift-card/Models/SellGiftCard.model';
import { SellGiftCard } from '~/engage/sell-gift-card/Components/SellGiftCard';
import { SellGiftCardView } from '~/engage/sell-gift-card/Components/SellGiftCard.view';
import { CameraScanningView } from '~/engage/sell-gift-card/Components/CameraScanning.view';
import { GunScanningView } from '~/engage/sell-gift-card/Components/GunScanning.view';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { MagicOverlayStoreFactory } from '~/components/magic-overlay/Stores/MagicOverlay.store';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { GiftCardScannerStoreFactory } from '~/engage/gift-card-scanner/Stores/GiftCardScanner.store';
import { noop } from '~/util/noop';
import sellGiftCardStyles from '~/engage/sell-gift-card/Components/SellGiftCard.module.scss';
import giftCardScannerStyles from '~/engage/gift-card-scanner/Components/GiftCardScanner.module.scss';
import { cartChangedAction } from '~/cart/Actions/cartChanged.action';

const views = {
	main: 'main',
	cameraScanning: 'cameraScanning',
	gunScanning: 'gunScanning',
} as const;

type View = typeof views;
type ViewKey = keyof View;
type ViewStateConfig = {
	[key in ViewKey]: ViewStateView
};

type ViewStateModalConfig = {
	[key in ViewKey]: object
};

class SellGiftCardStore {
	globalDynamic!: GlobalDynamicStore;

	magicModal!: MagicModalStore;

	model!: SellGiftCardModel;

	overlay!: MagicOverlayStore;

	scanner!: GiftCardScannerStore;

	viewState!: ViewStateStore;

	viewStateConfig: ViewStateConfig = {
		main: {
			component: SellGiftCardView,
			onEnter: (viewState: ViewStateView, store: SellGiftCardStore) => {
				const {
					barcodeButtonClickHandler = noop,
					tryAgainClickHandler = noop,
				} = store;

				return {
					barcodeButtonClickHandler,
					model: store.model,
					tryAgainClickHandler,
				};
			},
		},
		cameraScanning: {
			component: CameraScanningView,
			onEnter: () => {
				this.scanner.reset();
				return {
					store: this.scanner,
				};
			},
		},
		gunScanning: {
			component: GunScanningView,
			onEnter: () => {
				const {
					cancelGunScannerClickHandler = noop,
				} = this;

				this.scanner.init();
				return {
					cancelGunScannerClickHandler,
				};
			},
			onExit: () => {
				this.scanner.cleanup();
			}
		}
	};

	viewStateModalConfig: ViewStateModalConfig = {
		cameraScanning: {
			// Transition looks better without the current modal collapsing as the scanning overlay fades in.
			get height() {
				const elem = document.querySelector<HTMLDivElement>('.MagicModal');

				if (!elem) {
					return 0;
				}
				return elem.offsetHeight;
			},
			showCloseButton: false,
		},
		gunScanning: {
			showCloseButton: false,
		},
		main: {},
	};

	viewStateOptions = {
		onAfterEnterEach: (viewState: ViewStateView) => {
			const { key = 'main' } = viewState;

			this.alterModal(key as ViewKey);
			window.scrollTo(0, 0);
		},
	};

	alterModal(viewStateKey: ViewKey) {
		const modalConfig = this.viewStateModalConfig[viewStateKey];

		this.magicModal.alterModal({
			height: 'auto',
			title: 'Sell a Gift Card',
			showCloseButton: true,
			...(modalConfig) && {
				...modalConfig
			},
			id: cn(sellGiftCardStyles['SellGiftCard'], {
				[giftCardScannerStyles['GiftCardScanner']]: viewStateKey === 'cameraScanning',
			}),
		});
	}

	barcodeButtonClickHandler() {
		if (this.scanner.model.isIpad) {
			this.openCameraScannerOverlay();
		} else {
			this.viewState.goTo('gunScanning');
		}
	}

	cancelGunScannerClickHandler() {
		this.showBarcodeButton();
		this.model.form.updateModelValue('cardNumber', '');
		this.viewState.goTo('main');
	}

	hideBarcodeButton() {
		this.model.showBarcodeButton = false;
	}

	init() {
		this.viewState.goTo('main');
		this.magicModal.openModal({
			id: sellGiftCardStyles['SellGiftCard'],
			maxWidth: 470,
			title: 'Sell a Gift Card',
			content: {
				children: <SellGiftCard overlay={this.overlay} viewState={this.viewState} />,
			},
			onCloseModal: () => {
				this.scanner.cleanup();
			},
		});
	}

	onAddGiftCardToCartFailure(error: AxiosError) {
		this.model.error = new PromiseError(error);
	}

	onAddGiftCardToCartSuccess() {
		if (cartChangedAction) {
			if (cartChangedAction.getNumListeners()) {
				cartChangedAction.dispatch();
			}
		}
		this.globalDynamic.fetchData();
		this.magicModal.closeModal();
	}

	onCloseScannerOverlay() {
		const {
			scanner: {
				model: {
					activationCode = '',
					cardUpc = '',
				} = {},
			} = {},
			model: {
				form: {
					model: formModel = {},
					plugins: {
						formValidator: {
							validateField = noop,
						} = {},
					} = {},
				} = {},
			} = {},
		} = this;
		const newFormData = {
			cardNumber: activationCode,
			cardUpc,
		};

		this.viewState.goTo('main');
		formModel.cardNumber = newFormData.cardNumber;
		formModel.cardUpc = newFormData.cardUpc;
		if (formModel.cardNumber.length) {
			validateField('cardNumber');
			this.hideBarcodeButton();
		} else {
			this.showBarcodeButton();
		}
	}

	onGunScannerSuccess() {
		this.model.form.model.cardNumber = this.scanner.model.giftCardNumber;
		this.model.form.model.cardUpc = this.scanner.model.cardUpc;
		this.hideBarcodeButton();
		this.viewState.goTo('main');
	}

	openCameraScannerOverlay() {
		this.viewState.goTo('cameraScanning', {
			formModel: this.model.form.model,
		});
	}

	async promiseHandler(promise: AxiosPromise) {
		try {
			await promise;
			this.onAddGiftCardToCartSuccess();
		} catch (error: unknown) {
			if (!axios.isAxiosError(error)) {
				console.error(error);
				return;
			}
			const mock = {
				response: {
					data: {
						errors: [{
							errorKey: 'invalidGiftCardUpc',
							errorValues: {},
							debugMessage: 'foobar',
							fieldError: false,
						}],
					}
				},
				isAxiosError: true,
			} as AxiosError;
			this.onAddGiftCardToCartFailure(mock);
		}
	}

	showBarcodeButton() {
		this.model.showBarcodeButton = true;
	}

	submitHandler(form: any) {
		this.model.error = null;
		form.plugins.formValidator.validateForm();

		if (form.plugins.formValidator.hasErrors) {
			return Promise.reject('Form is not valid.');
		}
		const promise = axios.post(this.model.addGiftCardToCartLink, this.model.payload);

		this.overlay.startLoading(promise);
		return promise;
	}

	tryAgainClickHandler() {
		this.model.form.plugins.formValidator.validateForm();
		this.model.form.fields.amount.plugins.usCurrency.autoNumeric.set('');
		this.model.form.updateModelValue('amount', '');
		this.model.form.updateModelValue('cardNumber', '');
		this.model.form.updateModelValue('cardUpc', '');
		this.model.error = null;
		this.showBarcodeButton();
	}

	constructor() {
		makeObservable(this, {
			alterModal: action.bound,
			barcodeButtonClickHandler: action.bound,
			cancelGunScannerClickHandler: action.bound,
			hideBarcodeButton: action.bound,
			init: action.bound,
			onAddGiftCardToCartFailure: action.bound,
			onAddGiftCardToCartSuccess: action.bound,
			onCloseScannerOverlay: action.bound,
			onGunScannerSuccess: action.bound,
			openCameraScannerOverlay: action.bound,
			promiseHandler: action.bound,
			showBarcodeButton: action.bound,
			submitHandler: action.bound,
			tryAgainClickHandler: action.bound,
		});
	}
}

export const SellGiftCardStoreFactory = {
	create(
		addGiftCardToCartLink: string,
		featureTogglesModel: FeatureTogglesModel,
		magicModal: MagicModalStore,
		globalDynamicStore: GlobalDynamicStore,
	) {
		if (!magicModal) {
			throw new Error('magicModal not found.');
		}
		if (!globalDynamicStore) {
			throw new Error('globalDynamicStore not found.');
		}
		const store = new SellGiftCardStore();

		// @ts-ignore
		global.store = store;
		store.magicModal = magicModal;
		store.globalDynamic = globalDynamicStore;
		store.model = new SellGiftCardModel(addGiftCardToCartLink, store.submitHandler, store.promiseHandler);
		store.viewState = new ViewStateStore(store, store.viewStateConfig, store.viewStateOptions);

		const overlay = MagicOverlayStoreFactory.create({ useOpaqueFrosty: true });
		if (overlay) {
			store.overlay = overlay;
		}
		store.model.form = new FormBuilder(store.model.formModel, store.model.formSettings);
		store.scanner = GiftCardScannerStoreFactory.create({
			featureTogglesModel,
			onCloseScannerOverlay: store.onCloseScannerOverlay,
			onGunScannerSuccess: store.onGunScannerSuccess,
		});

		store.init();
		return store;
	},
};
