import { action, computed, autorun, makeObservable, observable } from 'mobx';
import { type AxiosPromise } from 'axios';

import { type IFormSettings } from '~/util/formz/Interfaces/FormSettings.interface';
import { type PromiseError } from '~/util/messaging/promise-error/PromiseError';
import { autoFocusHelper } from '~/util/autoFocusHelper';
import { isOnServer } from '~/global/global.constants';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';
import { FormReactionsPlugin } from '~/util/formz/plugins/FormReactionsPlugin';
import styles from '~/engage/sell-gift-card/Components/SellGiftCard.module.scss';

type FormModel = {
	amount: string
	cardNumber: string
	cardUpc: string
};
type SubmitHandler = (form: any) => Promise<any>;
type PromiseHandler = (promise: AxiosPromise) => void;

export class SellGiftCardModel {
	addGiftCardToCartLink: string;

	error?: PromiseError | null;

	form: any;

	formModel: FormModel = {
		amount: '',
		cardNumber: '',
		cardUpc: '',
	};

	promiseHandler: PromiseHandler;

	showBarcodeButton = true;

	submitHandler: SubmitHandler;

	get formSettings(): IFormSettings {
		const self = this;

		return {
			id: 'sell-gift-card-form',
			reactProps: {
				method: 'post',
				className: `formz ${styles['SellGiftCard-form']} tw-space-y-4`,
			},
			settings: {
				plugins: [
					new ValidateAndAjaxSubmitPlugin({
						ajaxSubmit: {
							submitHandler: this.submitHandler,
							promiseHandler: this.promiseHandler,
						},
					}),
					new FormReactionsPlugin({
						reaction: (form: any) => {
							if (isOnServer) {
								return;
							}
							// Auto format cardNumber
							autorun(() => {
								const {
									fields: {
										cardNumber: {
											control: {
												reactPropsMap = {},
											} = {},
										} = {},
									} = {},
									model: {
										cardNumber = '',
									} = {},
								} = form;

								reactPropsMap.set('value', this.formatCardNumber(cardNumber));
							});
						},
					}),
				],
			},
			fields: {
				amount: {
					reactProps: {
						className: styles['SellGiftCard-amountFieldGroup'],
					},
					label: {
						reactProps: {
							children: 'Amount',
							className: styles['SellGiftCard-amountLabel'],
						},
					},
					control: {
						reactProps: {
							type: 'text',
							placeholder: '$',
							className: styles['SellGiftCard-amountControl'],
							autoComplete: 'off',
							inputMode: 'numeric',
							ref: (component: any) => {
								if (self.showBarcodeButton) {
									return;
								}
								autoFocusHelper(component, 200);
							},
						},
					},
					settings: {
						plugins: [{
							pluginId: 'usCurrency',
							settings: {
								fullDollarAmounts: true,
								maxPayment: 2000,
							},
						}],
						validationConstraints: {
							presence: {
								message: '^Enter an amount.',
							},
							numericality: {
								greaterThanOrEqualTo: 25,
								lessThanOrEqualTo: 2000,
								message: '^Enter a valid amount.',
							},
						},
					},
				},
				cardNumber: {
					label: {
						reactProps: {
							children: 'Gift Card Number',
						},
					},
					control: {
						reactProps: {
							type: 'text',
							disabled: true,
							className: `${styles['SellGiftCard-cardNumberControl']} tw-opacity-100`,
						},
					},
					settings: {
						validationConstraints: {
							presence: true,
							length: {
								minimum: 19,
								maximum: 19,
							},
						},
					},
				},
			},
		};
	}

	get cardUpc() {
		const {
			form: {
				model: {
					cardNumber = '',
				} = {},
			} = {},
		} = this;

		if (!cardNumber) {
			return '';
		}
		return cardNumber.substr(0, 11);
	}

	get cardNumber() {
		const {
			form: {
				model: {
					cardNumber = '',
				} = {},
			} = {},
		} = this;

		if (!cardNumber) {
			return '';
		}
		return cardNumber.substr(-19);
	}

	formatCardNumber(cardNumber = '') {
		const result = cardNumber.split('').map((digit, i) => {
			const digitString = digit.toString();

			return (i && i % 4 === 0) ? ` ${digitString}` : digitString;
		});

		return result.join('');
	}

	get payload() {
		return this.form.model;
	}

	constructor(addGiftCardToCartLink: string, submitHandler: SubmitHandler, promiseHandler: PromiseHandler) {
		if (!addGiftCardToCartLink) {
			throw new Error('addGiftCardToCartLink not found.');
		}
		if (!submitHandler) {
			throw new Error('submitHandler not found.');
		}
		if (!promiseHandler) {
			throw new Error('promiseHandler not found.');
		}
		makeObservable(this, {
			cardNumber: computed,
			cardUpc: computed,
			error: observable.ref,
			formatCardNumber: action.bound,
			showBarcodeButton: observable,
			payload: computed,
		});
		this.addGiftCardToCartLink = addGiftCardToCartLink;
		this.submitHandler = submitHandler;
		this.promiseHandler = promiseHandler;
	}
}
